<template>
  <v-btn
    @click="closeDialog"
    class="position-button"
    icon
  >
    <v-icon
      color="#000"
      size="18px"
    >
      mdi-close
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "CloseDialogComponent",
  methods: {
    closeDialog: function () {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style scoped>
.position-button {
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>